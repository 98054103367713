import React from 'react';
import axios from 'axios';
import ReactDOM from 'react-dom/client';
import { FormIframe } from '@src/embed/components';
import { FormConfig } from '@src/types.t';

type LoadFormProps = {
  fid: string;
  aid: string;
};

const loadConfig = async (props: LoadFormProps): Promise<FormConfig | null> => {
  const apiUrl = `${process.env.API_URL}/form/public/${props.aid}/${props.fid}`;

  const res = await axios.get(apiUrl);

  if (res.status < 200 || res.status >= 300) {
    return Promise.reject(null);
  }

  return Promise.resolve(res.data);
};

type EmbedFormProps = {
  fid: string;
  aid: string;
  target?: string;
  disable?: boolean;
};

export const EmbedForm = (props: EmbedFormProps) => {
  const { fid, aid, target, disable } = props;

  const scriptNode = document.currentScript;

  if (scriptNode?.parentElement == null && target == null) {
    return;
  }

  let targetEl = scriptNode?.parentElement;
  if (target != null) {
    const givenTargetEl = document.querySelector(target) as HTMLElement;
    if (givenTargetEl != null) {
      targetEl = givenTargetEl;
    }
  }

  const appContainer = document.createElement('div');
  const container = ReactDOM.createRoot(appContainer as HTMLElement);

  const elementID = 'swoo-embd-form-root-vi94fdw349dg';

  if (targetEl == null) {
    console.log('target element could not get found');
    return;
  }

  loadConfig(props)
    .then((formConfig) => {
      if (formConfig == null) {
        console.error('could not load form config');
        return;
      }

      container.render(
        <React.StrictMode>
          <FormIframe
            fid={fid}
            aid={aid}
            targetId={elementID}
            disable={disable}
          >
            <div id={elementID}></div>
          </FormIframe>
        </React.StrictMode>
      );

      targetEl?.appendChild(appContainer);
    })
    .catch((e) => {
      console.error(e);
    });
};
