import React, { CSSProperties } from 'react';
import { UseFormRegister } from 'react-hook-form';
import parse from 'html-react-parser';
import {
  FieldConfig,
  HTML_FIELD_TYPE,
  INPUT_FIELD_TEXTAREA_TYPE,
  INPUT_FIELD_TYPE,
  InputFieldConfig,
  StyleConfig
} from '@src/types.t';

export type FieldBuilderProps = {
  style: StyleConfig;
  fieldConfig: FieldConfig;
  register: UseFormRegister<any>;
};

export const FieldBuilder = ({
  fieldConfig,
  register,
  style
}: FieldBuilderProps) => {
  if (fieldConfig.type === INPUT_FIELD_TYPE && fieldConfig.input != null) {
    return (
      <InputFieldBuilder
        style={style}
        input={fieldConfig?.input}
        register={register}
      />
    );
  }

  if (fieldConfig.type === HTML_FIELD_TYPE && fieldConfig.html != null) {
    return <HtmlFieldBuilder html={fieldConfig.html} />;
  }

  return <></>;
};

type HtmlFieldBuilderProps = {
  html: string;
};

export const HtmlFieldBuilder = (props: HtmlFieldBuilderProps) => {
  const { html } = props;

  return (
    <div className="swoo-w-full swoo-mt-5 swoo-prose swoo-max-w-none">
      {parse(html)}
    </div>
  );
};

export type InputFieldBuilderProps = {
  style: StyleConfig;
  input: InputFieldConfig;
  register: UseFormRegister<any>;
};

export const InputFieldBuilder = ({
  input,
  register,
  style
}: InputFieldBuilderProps) => {
  const iptStyle: CSSProperties = {
    fontSize: `${style.font_sizes.input}px`,
    borderRadius: `${style.borders.radius}${style.borders.unit}`
  };

  if (style.padding != null && style.padding.input != null) {
    iptStyle.padding = style.padding.input;
  }

  return (
    <div className="swoo-w-full swoo-mt-5">
      {input.label != null && (
        <label
          style={{ fontSize: `${style.font_sizes.label}px` }}
          className="swoo-block swoo-font-normal swoo-leading-6 swoo-text-swoo-label-600"
        >
          {input.label}
          {input.required != null && input.required === true ? (
            <span title="required" className="swoo-text-red-600">
              {' '}
              *
            </span>
          ) : (
            ''
          )}
        </label>
      )}
      <div className="swoo-mt-1">
        <InputField
          input={input}
          register={register}
          style={iptStyle}
          className={`swoo-block swoo-w-full swoo-border-0 swoo-py-2 swoo-text-gray-900 swoo-shadow-sm swoo-ring-1 swoo-ring-inset swoo-ring-gray-300 placeholder:swoo-text-gray-400 focus:swoo-ring-2 focus:swoo-ring-inset sm:swoo-text-sm sm:swoo-leading-6 focus:swoo-ring-swoo-input-ring-600`}
        />
      </div>
    </div>
  );
};

type InputFieldProps = {
  input: InputFieldConfig;
  style: CSSProperties;
  register: UseFormRegister<any>;
  className?: string;
};

const InputField = (props: InputFieldProps) => {
  const { input, style, register, className } = props;
  if (input.type === INPUT_FIELD_TEXTAREA_TYPE) {
    return (
      <textarea
        {...input}
        rows={4}
        style={style}
        className={className}
        {...register(input.id)}
      ></textarea>
    );
  }

  return (
    <input
      {...input}
      style={style}
      className={className}
      {...register(input.id)}
    ></input>
  );
};
