import React, { CSSProperties, useState } from 'react';
import axios from 'axios';
import { SubmitHandler, UseFormRegister, useForm } from 'react-hook-form';
import getUserLocale from 'get-user-locale';
import Markdown from 'react-markdown';

import {
  FieldConfig,
  FormProps,
  SUBMIT_SUCCESS_MARKDOWN,
  StyleConfig
} from '@src/types.t';
import { FieldBuilder } from './FieldBuilder';
import { SortFieldsOrRows } from '@src/utils';

const isFrame = () => {
  try {
    return window.top !== window.self;
  } catch (e) {
    return true;
  }
};

export const FormBuilder = (props: FormProps) => {
  const [submitSuccess, setSubmitSuccess] = useState(false);

  const { config, aid, disable } = props;

  const { register, handleSubmit } = useForm();

  const btnStyle: CSSProperties = {
    fontSize: `${config.style.font_sizes.button}px`,
    borderRadius: `${config.style.borders.radius}${config.style.borders.unit}`
  };

  if (config.style.padding != null && config.style.padding.button != null) {
    btnStyle.padding = config.style.padding.button;
  }

  const onSubmit: SubmitHandler<any> = (data) => {
    const context = {
      user_agent: window.navigator.userAgent,
      page_location: isFrame()
        ? window.parent.location.href
        : window.location.href,
      page_title: isFrame()
        ? window.parent.document.title
        : window.document.title,
      is_frame: isFrame(),
      language: window.navigator.language,
      locale: getUserLocale()
    };

    if (disable === true) {
      console.log('Sawoo Form disabled. No data submitted.');
      setSubmitSuccess(true);
      return;
    }

    const apiUrl = process.env.FORM_SUBMISSION_URL + aid;
    axios
      .post(apiUrl, {
        data,
        form_id: config.form_id,
        context
      })
      .then((res) => {
        if (res.status < 200 || res.status >= 300) {
          console.error(res.data);
        }

        setSubmitSuccess(true);
      })
      .catch((e) => console.log(e));
  };

  if (
    submitSuccess === true &&
    config.on_success.type === SUBMIT_SUCCESS_MARKDOWN
  ) {
    return <Markdown>{config.on_success.content}</Markdown>;
  }

  return (
    <div className="swoo-pb-5">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={`swoo-grid swoo-grid-rows-${config.rows.length + 1}`}>
          {config.rows.sort(SortFieldsOrRows).map((value: any, i) => (
            <FormRowBuilder
              key={i}
              fields={value.fields}
              register={register}
              style={config.style}
            />
          ))}
          <div className="swoo-mt-8 ">
            <button
              type="submit"
              style={btnStyle}
              className={`swoo-w-full swoo-px-3 swoo-py-2 swoo-font-medium swoo-text-swoo-button-text-600 swoo-shadow-sm swoo-bg-swoo-button-600 hover:swoo-bg-swoo-button-500 focus-visible:swoo-outline focus-visible:swoo-outline-2 focus-visible:swoo-outline-offset-2 focus-visible:swoo-outline-swoo-button-500`}
            >
              {config.submit.label}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

type FormRowBuilderProps = {
  style: StyleConfig;
  fields: Array<FieldConfig>;
  register: UseFormRegister<any>;
};

export const FormRowBuilder = (props: FormRowBuilderProps) => {
  const { fields, register, style } = props;
  const cols = fields.length;

  return (
    <div
      className={`swoo-flex swoo-flex-wrap min-[500px]:swoo-flex-nowrap swoo-grid-cols-${cols} swoo-gap-x-6`}
    >
      {fields.sort(SortFieldsOrRows).map((value: any, i) => {
        return (
          <FieldBuilder
            key={i}
            fieldConfig={value}
            register={register}
            style={style}
          />
        );
      })}
    </div>
  );
};
