import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from '@src/form/App';
// import reportWebVitals from './reportWebVitals';

type RenderProps = {
  fid: string;
  aid: string;
  target: string;
  disable?: boolean;
};

export const Render = (props: RenderProps) => {
  const targetEl = document.querySelector(props.target) as HTMLElement;

  const root = ReactDOM.createRoot(targetEl);

  root.render(
    <React.StrictMode>
      <Create {...props} />
    </React.StrictMode>
  );
};

type CreateProps = {
  fid: string;
  aid: string;
  disable?: boolean;
};

export const Create = (props: CreateProps) => {
  return <App {...props} />;
};
