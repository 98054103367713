export const INPUT_FIELD_TYPE = 'input';
export const HTML_FIELD_TYPE = 'html';
export const INPUT_FIELD_TEXTAREA_TYPE = 'textarea';

export const SUBMIT_SUCCESS_MARKDOWN = 'md';

export type OnSuccess = {
  type: string;
  content: any;
};

export type SubmitButton = {
  label: string;
};

export type FormConfig = {
  form_id: string;
  rows: Array<FormRowConfig>;
  on_success: OnSuccess;
  submit: SubmitButton;
  style: StyleConfig;
};

export type FormRowConfig = {
  fields: Array<FieldConfig>;
  position: number;
};

export type StyleConfig = {
  width: string;
  height: number;
  colors: ColorPalette;
  font_sizes: FontSizeConfig;
  borders: BorderConfig;
  padding?: PaddingConfig;
};

export type PaddingConfig = {
  input: string;
  button: string;
};

export type FieldConfig = {
  type: string;
  position: number;
  input?: InputFieldConfig;
  html?: string;
};

export type InputFieldConfig = {
  id: string;
  type: string;
  placeholder: string;
  label?: string;
  required?: boolean;
};

export type ColorPalette = {
  button_text: string;
  button_bg: string;
  button_accent: string;
  label: string;
  input_ring: string;
};

export type FontSizeConfig = {
  button: number;
  label: number;
  input: number;
};

export type BorderConfig = {
  radius: number;
  unit: string;
};

export type FormProps = {
  config: FormConfig;
  aid: string;
  disable: boolean;
};
