import React from 'react';

import { FormBuilder } from '@src/form/components';
import { FormProps } from '../types.t';

export const Form = (props: FormProps) => {
  const { config } = props;

  return (
    <div
      id="sawoo-forms-form"
      className="swoo-max-w-7xl swoo-px-4 sm:swoo-px-6 lg:swoo-px-8"
      style={{ width: `${config.style.width}` }}
    >
      <FormBuilder {...props} />
    </div>
  );
};
