import React, { useEffect, useState } from 'react';
import { HelmetProvider, Helmet } from 'react-helmet-async';
import axios from 'axios';

import { Form } from '@src/form/Form';
import { FormConfig } from '@src/types.t';

type AppProps = {
  fid: string;
  aid: string;
  disable?: boolean;
};

function App(props: AppProps) {
  const { fid, aid, disable } = props;

  const [formConfig, setFormConfig] = useState<FormConfig | null>(null);
  const [loadingError, setLoadingError] = useState(null);

  const apiUrl = `${process.env.API_URL}/form/public/${aid}/${fid}`;
  useEffect(() => {
    if (formConfig != null || loadingError != null) {
      return;
    }

    axios
      .get(apiUrl)
      .then((res) => {
        if (res.status < 200 || res.status >= 300) {
          console.error(res.data);
        }

        setFormConfig(res.data);
      })
      .catch((e) => {
        console.log(e);
        setLoadingError(e);
      });
  });

  if (loadingError != null) {
    return (
      <>SAWOO Form can not be shown. Please check the embed code and params.</>
    );
  }

  if (formConfig == null) {
    return <></>;
  }

  return (
    <HelmetProvider>
      {' '}
      <Helmet>
        <style type="text/css">{`
              :root {
                --swoo-input-ring-color: ${formConfig.style.colors.input_ring};
                --swoo-label-color: ${formConfig.style.colors.label};
                --swoo-button-accent-color: ${formConfig.style.colors.button_accent};
                --swoo-button-bg-color: ${formConfig.style.colors.button_bg};
                --swoo-button-text-color: ${formConfig.style.colors.button_text};
              }
         `}</style>
      </Helmet>
      <Form
        config={formConfig}
        aid={aid}
        disable={disable == null ? false : disable}
      />
    </HelmetProvider>
  );
}

export default App;
