import React, { useState, useEffect } from 'react';
import { createPortal } from 'react-dom';

type FormIframeProps = {
  children: React.ReactNode;
  aid: string;
  fid: string;
  targetId: string;
  disable?: boolean;
};

// type SawooLib = {
//   Form: () => React.ReactNode;
// };

export const FormIframe = (props: FormIframeProps) => {
  const [contentRef, setContentRef] = useState<HTMLIFrameElement | null>(null);
  const [frameHeight, setFrameHeight] = useState(0);
  const [formScript, setFormScript] = useState(false);

  const { fid, aid, targetId, disable } = props;

  const resizeObserver = new ResizeObserver((entries) => {
    if (contentRef == null) {
      return;
    }

    entries.forEach((entry) => {
      if (entry.target.tagName !== 'BODY') {
        return;
      }
      // console.log(entry);
      if (entry.contentRect.height === 0) {
        return;
      }

      if (entry.contentRect.height > frameHeight) {
        setFrameHeight(entry.contentRect.height);
      }
    });
  });

  useEffect(() => {
    if (
      contentRef == null ||
      contentRef.contentWindow == null ||
      formScript !== false
    ) {
      return;
    }

    resizeObserver.observe(contentRef.contentWindow.document.body);
    const script = createFormScript(contentRef);
    setFormScript(true);
    contentRef.contentDocument?.body.appendChild(script);
  }, [contentRef, resizeObserver, formScript]);

  const createFormScript = (target: HTMLIFrameElement) => {
    const script = document.createElement('script');
    script.setAttribute('type', 'text/javascript');
    script.src = `${process.env.FORM_SCRIPT_BASE_URL}/embed/script.js`;

    script.addEventListener('load', () => {
      // console.log(target);
      // console.log(window);
      // console.log(contentRef);
      // console.log(contentRef?.contentWindow);

      const renderScript = document.createElement('script');
      renderScript.text = `Sawoo.Form.Render({ fid: "${fid}", aid: "${aid}", target: "#${targetId}", disable: ${disable} });`;

      target?.contentDocument?.body.appendChild(renderScript);
    });

    return script;
  };

  // onload handler for Firefox
  const handleFrameLoad = (e: any) => {
    if (e.target?.tagName !== 'IFRAME') {
      return;
    }
    setContentRef(null); // unset contentRef so it does not add the frame again by the useEffect hook

    const frame = e.target as HTMLIFrameElement;
    const div = document.createElement('div');
    div.setAttribute('id', targetId);
    frame.contentDocument?.body.appendChild(div);

    const css = document.createElement('link');
    css.setAttribute(
      'href',
      `${process.env.FORM_SCRIPT_BASE_URL}/embed/css/form.css`
    );
    css.setAttribute('rel', 'stylesheet');

    const base = document.createElement('base');
    base.setAttribute('href', window.location.href);
    base.setAttribute('taget', '_parent');

    const script = createFormScript(frame);

    if (frame.contentDocument != null) {
      resizeObserver.observe(frame.contentDocument.body);
    }
    setFormScript(true); // even if not checked in Firefox leave this to ensure the contentRef onload does not fire
    frame.contentDocument?.head.appendChild(css);
    frame.contentDocument?.head.appendChild(base);
    frame.contentDocument?.body.appendChild(script);
  };

  const bodyNode = contentRef?.contentWindow?.document?.body;
  const headNode = contentRef?.contentWindow?.document?.head;

  return (
    <iframe
      title="SAWOO Communities Form"
      width="100%"
      style={{ position: 'static', border: 'none' }}
      id="swoo-frame"
      ref={setContentRef}
      height={`${frameHeight}`}
      onLoad={handleFrameLoad}
    >
      {headNode &&
        createPortal(
          <>
            <base href={window.location.href} target="_parent" />
            <link
              href={`${process.env.FORM_SCRIPT_BASE_URL}/embed/css/form.css`}
              rel="stylesheet"
            ></link>
          </>,
          headNode
        )}
      {bodyNode && createPortal(props.children, bodyNode)}
    </iframe>
  );
};
