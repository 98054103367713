// import React from 'react';
// import ReactDOM from 'react-dom/client';
// import './index.css';
// import App from '@src/form/App';
import { Create, Render } from '@src/form';
// import reportWebVitals from './reportWebVitals';

// type RenderProps = {
//   fid: string;
//   aid: string;
//   element_id: string;
// };

// export const Render = (props: RenderProps) => {
//   const root = ReactDOM.createRoot(
//     document.getElementById(props.element_id) as HTMLElement
//   );

//   root.render(
//     <React.StrictMode>
//       <Create {...props} />
//     </React.StrictMode>
//   );
// };

// type CreateProps = {
//   fid: string;
//   aid: string;
// };

// export const Create = (props: CreateProps) => {
//   return <App {...props} />;
// };

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();

export const Form = {
  Render: Render,
  Create: Create
};

export * from '@src/embed';
